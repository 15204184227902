import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq9 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/orange-background-woman-1200x600.jpg"
                  alt="Woman"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  Are Lumineers<sup>&reg;</sup> safe?
                </h2>
                <p>
                  DenMat has been fabricating dental veneers since 1984 and
                  under the Lumineers<sup>&reg;</sup> brand since 2004. Millions
                  of Lumineers have been placed by thousands of dentists.
                  Lumineers are fabricated exclusively by the DenMat Lab in
                  Lompoc, California, USA, and are manufactured under strict
                  guidelines by technicians that specialize in crafting esthetic
                  dental veneers. Other brands may be made outside of the United
                  States where quality and materials may not be held to as high
                  of a standard as DenMat Lab.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../about/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq9
